const locale = localStorage.getItem("locale");

export default {
  notification: null,
  pendingRequestsCount: 0,
  locale: locale || process.env.VUE_APP_I18N_LOCALE || "en",
  filterAreaVisible: false,
  appDrawerVisible: false,
  appSideNavigationVisible: true,
  appSideNavigationManuallyTriggered: false,
};
