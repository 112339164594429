export default [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/auth/Login.vue');
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: true,
      hideNavbar: true,
    },
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/auth/ResetPassword.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth password-reset',
      publicPage: true,
      hideNavbar: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/auth/ForgotPassword.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth password-reset',
      publicPage: true,
      hideNavbar: true,
    },
  },
];
