<template>
  <div>
    <v-app-bar
      color="grey lighten-3"
      permanent
      app
      light
      height="60px"
      elevation="0"
    >
      <!-- <v-toolbar-title>Dashboard</v-toolbar-title> -->
      <v-spacer></v-spacer>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="secondary" size="38">
              <span class="white--text text-h6">{{
                user.name.match(/\b(\w)/g).join('')
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="myAccount">
            <v-list-item-title>My Account</v-list-item-title>
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      absolute
      app
      color="primary"
      dark
      expand-on-hover
      height="100%"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <!-- <v-img
              src="https://randomuser.me/api/portraits/women/85.jpg"
            ></v-img> -->
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          class="mt-2"
          :to="item.url"
          v-show="
            $router
              .resolve({ path: item.url })
              .route.meta.adminType.includes($store.state.auth.user.admin_type)
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import AuthService from '../../services/auth-service';
import { mapGetters } from 'vuex';
export default {
  components: {},
  data: () => ({
    drawer: true,
    items: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        url: '/',
        disable: false,
      },
      {
        title: 'Employees',
        icon: 'mdi-account-group',
        url: '/members',
        disable: false,
      },
      {
        title: 'Form Submitions',
        icon: 'mdi-file-document-check-outline',
        url: '/forms',
        disable: false,
      },
      // {
      //   title: 'Users',
      //   icon: 'mdi-account-multiple',
      //   url: '/users',
      //   disable: false,
      // },
      {
        title: 'Website Settings',
        icon: 'mdi-web-check',
        url: '/settings',
        disable: false,
      },
    ],
    mini: true,
    group: null,
  }),
  methods: {
    myAccount() {
      this.$router.push('/my-account');
    },
    logout() {
      AuthService.logout();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    navButtons() {
      return ['login', 'register', 'forgot-password'].includes(
        this.$route.name
      );
    },
  },
};
</script>
<style scoped>
.logo-item {
  min-height: 230px;
}
.img-logo {
  transition-timing-function: linear;
}
</style>
