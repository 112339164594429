  export default [
    {
      path: '/',
      name: 'index',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(
          /* webpackChunkName: "about" */ '../views/dashboard/index.vue'
        );
      },
      meta: {
        publicPage: false,
        adminType: ['admin', 's_admin', 'accounts'],
      },
    },
    {
      path: '/forms',
      name: 'forms',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(
          /* webpackChunkName: "about" */ '../views/dashboard/Forms.vue'
        );
      },
      meta: {
        publicPage: false,
        adminType: ['admin', 's_admin'],
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(
          /* webpackChunkName: "about" */ '../views/dashboard/WebSettings.vue'
        );
      },
      meta: {
        publicPage: false,
        adminType: ['admin', 's_admin'],
      },
    },
  ];
