import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import validator from './plugins/validator';

Vue.config.productionTip = false;
import './sass/main.scss';


Vue.use(validator);
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
