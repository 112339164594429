export default {
  auth: {
    login: '/admin/login',
    logout: '/admin/logout',
    changePassword: 'admin/change-password',
    forgotPassword: 'admin/forgot-password',
    resetPassword: 'admin/reset-password',
  },
  user: {
    getUsers: 'admin/get-users',
    create: 'admin/create-user',
    update: 'admin/update-user',
    changeStatusUser: 'admin/change-status-user',
    delete: 'admin/delete-user',
  },
  common: {
    
  },
  dashboard: {
    getDashboardData: 'admin/get-dashboard',
    getPaymentDetails: 'admin/get-payment-details',
  },
  members: {
   
  },
  web :{
    getSettings : 'admin/get-web-settings',
    updateSettings: 'admin/update-web-settings',
  }
  
};
