const user = JSON.parse(localStorage.getItem('user'));

export default {
  user: {
    ...user,
    permissions:
      user?.roles
        ?.map((role) => role.permissions)
        .concat(user.permissions)
        .map((permission) => permission.name) || [],
  },
};
