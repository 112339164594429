import store from '@/store';
import httpService from './http-service';
import endpoints from '../utilities/endpoints';

export default {
  login(payload) {
    return httpService.post(endpoints.auth.login, payload).then((response) => {
      if (response.data.token) {
        store.commit('auth/SET_USER', response.data.user);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        window.location.replace('/');
      }
    });
  },

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return httpService.get(endpoints.auth.logout).then(() => {
      store.commit('auth/SET_USER', null);
      window.location.replace('/login');
    });
  },
  getUser() {
    return httpService.get('admin/user-info').then((response) => {
      if (response.data) {
        store.commit('auth/SET_USER', response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      }
    });
  },
  forgotPassword(payload) {
    return httpService.post(endpoints.auth.forgotPassword, payload);
  },
  resetPassword(payload) {
    return httpService.post(endpoints.auth.resetPassword, payload);
  },
  updateProfile(uuid, payload) {
    return httpService.put('profile/' + uuid, payload);
  },
};
